<script>
  import {
    mdiCheckboxOutline,
    mdiGiftOutline,
    mdiPrinterOutline,
    mdiViewCompactOutline
  } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";
  export default defineComponent({
    setup() {
      function goToSection(refName) {
        const element = this.$refs[refName];
        const top = element.$el ? element.$el.offsetTop : 0;
        window.scrollTo({
          top,
          behavior: "smooth"
        });
      }
      return {
        icons: {
          mdiCheckboxOutline,
          mdiGiftOutline,
          mdiPrinterOutline,
          mdiViewCompactOutline
        }
      };
    }
  });
</script>
<template>
  <div>
    <v-card flat>
      <v-card-title class="font-weight-semibold text-h3">
        <v-icon size="48" class="d-none d-lg-block mr-2">
          {{ icons.mdiGiftOutline }}
        </v-icon>
        <span>What's New in v4.2.0</span>
      </v-card-title>
      <v-card-text class="mt-n3 font-italic">
        updated 2023-08-24 @ 1:30pm EDT
      </v-card-text>
      <v-divider class="my-3"></v-divider>
      <v-card-text class="text-lg">
        Version 4.2.0 of TPM is a minor release that focuses on allowing users
        to:
        <ul>
          <li class="mt-2">
            <span class="font-weight-semibold">View</span> all IDRs in the
            system.
          </li>
          <li class="mt-2">
            <span class="font-weight-semibold">Filter</span> IDRs by:
            <ul>
              <li>Contract</li>
              <li>Proceed</li>
              <li>Permit</li>
              <li>Location</li>
              <li>Invoiced/Not Invoiced IDRs</li>
              <li>IDR Date</li>
              <li>One or many Contractors</li>
              <li>One or many Lead Inspectors</li>
            </ul>
          </li>
          <li class="mt-2">
            <span class="font-weight-semibold">Mark</span> one or many IDRs as
            having been invoiced.
          </li>
          <li class="mt-2">
            <span class="font-weight-semibold">Undo</span> an already invoiced
            IDR to correct a mistake.
          </li>
          <li class="mt-2">
            <span class="font-weight-semibold">Print</span> an IDR.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-divider class="my-5"></v-divider>
    <v-card flat>
      <v-card-title class="font-weight-semibold text-h6">
        <v-icon size="28" class="mr-2">
          {{ icons.mdiViewCompactOutline }}
        </v-icon>
        <span ref="view-filter">Viewing and Filtering IDRs</span>
      </v-card-title>
      <v-card-text class="text-lg">
        <p>
          In the navigation sidebar on the left, you can see that a new option
          called <strong>IDRs</strong> has been added. Clicking on this title
          will expand the submenu showing the <strong>IDR List</strong> option.
          Clicking on the link will take you to the <strong>IDRs</strong> page.
        </p>
        <p>
          The <strong>IDRs</strong> page displays a list of all IDRs in the
          system. You can filter the list by clicking on the
          <strong>Filter List</strong> button in the top right corner of the
          page. This will open a panel on the right side that allows you to
          filter the list by:
        </p>
        <ul class="my-3">
          <li>Contract</li>
          <li>Proceed</li>
          <li>Permit</li>
          <li>Location</li>
          <li>Invoiced/Not Invoiced IDRs</li>
          <li>IDR Date</li>
          <li>One or many Contractors</li>
          <li>One or many Lead Inspectors</li>
        </ul>
        <p>
          The filters "stack", meaning you can keep adding filters and watch the
          list of IDRs get smaller and smaller. You can remove a filter by
          clicking on the "x" on the blue chip next to the filter name; or, you
          you can click on <strong>Clear Filters</strong> at the top of the
          panel.
        </p>
      </v-card-text>
    </v-card>
    <v-divider class="my-5"></v-divider>
    <v-card flat>
      <v-card-title class="font-weight-semibold text-h6">
        <v-icon size="28" class="mr-2">
          {{ icons.mdiCheckboxOutline }}
        </v-icon>
        <span>Marking IDRs as "Invoiced"</span>
      </v-card-title>
      <v-card-text class="text-lg">
        <p>
          You can mark one or many IDRs as having been invoiced by selecting the
          checkbox next to the IDR(s) you want to mark and then clicking on the
          <strong>Mark as Invoiced</strong> button at the top of the page. This
          action will change the status to <strong>Invoiced</strong> (in green
          text) and an <strong>Undo</strong> button will appear in the far right
          column. If you've accidentally marked an IDR as
          <strong>Invoiced</strong>, you can click on the
          <strong>Undo</strong> button to change the status to
          <strong>Not Invoiced</strong>.
        </p>
      </v-card-text>
    </v-card>
    <v-divider class="my-5"></v-divider>
    <v-card flat>
      <v-card-title class="font-weight-semibold text-h6">
        <v-icon size="28" class="mr-2">
          {{ icons.mdiPrinterOutline }}
        </v-icon>
        <span>Printing an IDR</span>
      </v-card-title>
      <v-card-text class="text-lg">
        <p>
          First, it hould be noted that an IDR does not have to be marked as
          <strong>Invoiced</strong> in order to be printed. You can print an IDR
          by clicking on the <strong>printer icon</strong> in the far right
          column. This will open your browser's print dialog. You can then print
          the IDR to your local printer or save it as a PDF.
        </p>

        <p>
          <strong>NOTE:</strong> At the time of this release, there was limited
          data available to refine the print layout. As such, the print layout
          may be less than ideal for some IDRs. Please report all irregularities
          to the Admin so adjustments can be made. We will be working to improve
          the print layout in future releases.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>
